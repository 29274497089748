:root {
  --black: #37474f;
  --black-light: #56717c;
  --white: #f5f5f5;
  --white-dark: #cfd8dc;
  --accent: #3f51b5;
  --accent-dark: #283593;
}

* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: calc(1em + 1vw);
  font-weight: 400;
  color: var(--black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--white);
}

html,
body,
#root {
  height: 100%;
}

h1,
h2,
p {
  margin: 0;
}

button,
select {
  font-family: "Roboto", sans-serif;
}

@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    animation: none !important;
    transition: none !important;
  }
}
