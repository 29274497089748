.loading-spinner {
  display: inline-block;
  position: relative;
  width: 2em;
  height: 2em;
  opacity: 0.8;
  animation: loading-spinner_fade-in 0.3s;
}

.loading-spinner div {
  position: absolute;
  top: calc(2em * 0.4125);
  width: calc(2em * 0.1625);
  height: calc(2em * 0.1625);
  border-radius: 50%;
  background: white;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loading-spinner div:nth-child(1) {
  left: calc(2em * 0.1);
  animation: loading-spinner_scale-up 0.6s infinite;
}
.loading-spinner div:nth-child(2) {
  left: calc(2em * 0.1);
  animation: loading-spinner_translate 0.6s infinite;
}
.loading-spinner div:nth-child(3) {
  left: calc(2em * 0.4);
  animation: loading-spinner_translate 0.6s infinite;
}
.loading-spinner div:nth-child(4) {
  left: calc(2em * 0.7);
  animation: loading-spinner_scale-up 0.6s infinite reverse;
}

@keyframes loading-spinner_fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes loading-spinner_scale-up {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes loading-spinner_translate {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(2em * 0.3));
  }
}
