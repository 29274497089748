.modal {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  outline: none;
}
@media (min-height: 700px) and (min-width: 700px) {
  .modal {
    align-items: center;
  }
}
.modal,
.modal:after {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.modal:after {
  content: "";
  background: var(--black);
  opacity: 0.25;
}
.modal.animated:after {
  animation: modal_fade-in 0.2s ease-out;
}
@keyframes modal_fade-in {
  from {
    opacity: 0;
  }
}

.modal .content {
  z-index: 10001;
  background-color: var(--white);
  box-shadow: 0px 2px 74px rgba(0, 0, 0, 0.35);
  border-radius: 2px;
  width: 550px;
  max-height: 80vh;
  overflow: auto;
}
.modal.animated .content {
  animation: modal_slide-up 0.3s ease-out;
}
@media (min-width: 550px) and (min-height: 700px) {
  .modal .content {
    max-height: auto;
  }
  .modal.animated .content {
    animation: modal_scale-in 0.2s ease-out;
  }
}
@keyframes modal_slide-up {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
}
@keyframes modal_scale-in {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
}
