.icon-wrapper {
  padding: 0.2em;
}
.icon-wrapper.interactive {
  outline: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.2s ease-out;
}
.icon-wrapper.interactive:hover,
.icon-wrapper.interactive:focus {
  opacity: 1;
}

.icon-wrapper .icon {
  width: 0.85em;
  height: 0.85em;
}
.icon-wrapper .icon.auto-scale {
  width: 0.6em;
  height: 0.6em;
  font-size: calc(1em + 1vw);
}
