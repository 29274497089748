.home {
  font-size: min(max(16px, 4.7vw), 23px);
  background: var(--white);
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.home .content {
  margin-top: 4em;
  padding: 1.5em 1em;
  max-width: 19em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.home .about-button {
  top: 1em;
  right: 1em;
  position: absolute;
}
@media (min-height: 700px) {
  .home .about-button {
    top: 1.25rem;
    right: 1.25rem;
  }
}
@media (min-height: 700px) and (min-width: 700px) {
  .home .about-button {
    top: 2.5rem;
    right: 2.5rem;
  }
}
@media (min-height: 1200px) and (min-width: 1200px) {
  .home .about-button {
    top: 5rem;
    right: 5.5rem;
  }
}

.home header {
  text-align: center;
}
.home header h1 {
  font-size: 2.25em;
  font-weight: 500;
  padding-top: 0.4em;
}
.home header h1 span {
  color: var(--black-light);
}
.home header h1 .logo {
  position: absolute;
  width: 0.6em;
  height: 0.6em;
  transform: translateY(-50%) rotate(45deg);
  margin-left: 0.1em;
}
.home header:hover h1 .logo {
  animation: home_rotation 0.8s ease-out;
}

.home main {
  margin: 2em 0;
}
.home main form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}
.home main form > * {
  font-size: 0.8em;
  margin: 1em 0;
}
.home main form label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home main form .category select,
.home main form .toggle-section input ~ .toggle,
.home main button:not(:disabled),
.home footer a {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}
.home main form .category .info,
.home main form .toggle-section .info {
  font-weight: 500;
}
.home main form .category select,
.home main form .toggle-section .toggle {
  flex-shrink: 0;
  margin-left: 1em;
}
.home main form .category select,
.home main form .toggle-section input ~ .toggle * {
  font-size: 0.9em;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}
.home main form .category select:hover,
.home main form .category select:focus,
.home main form .toggle-section input:hover ~ .toggle *,
.home main form .toggle-section input:focus ~ .toggle * {
  border-color: var(--black-light);
}

.home main form .category {
  margin: 1.2em 0;
}
.home main form .category select {
  background: white;
  border-radius: 1.2em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  color: var(--black);
  border-width: 2px;
  border-style: solid;
  border-color: var(--white-dark);
  transition: border-color 0.2s ease-out;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-align: center;
  text-align-last: center;
}

.home main form .toggle-section input {
  opacity: 0;
}
.home main form .toggle-section .toggle {
  position: relative;
  top: -2px;
}
.home main form .toggle-section input ~ .toggle * {
  display: inline-block;
  background-color: white;
  padding-left: 1em;
  padding-right: 1em;
  color: var(--black);
  border-width: 2px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-color: var(--white-dark);
  transition-property: background-color, border-color;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  user-select: none;
}
.home main form .toggle-section input ~ .toggle .on {
  border-top-left-radius: 1.2em;
  border-bottom-left-radius: 1.2em;
  border-left-style: solid;
}
.home main form .toggle-section input:checked ~ .toggle .on {
  background-color: var(--accent);
  color: var(--white);
}
.home main form .toggle-section input ~ .toggle .off {
  border-top-right-radius: 1.2em;
  border-bottom-right-radius: 1.2em;
  border-right-style: solid;
}
.home main form .toggle-section input:not(:checked) ~ .toggle .off {
  background-color: var(--accent);
  color: var(--white);
}
.home main form .toggle-section p {
  color: var(--black-light);
  font-size: 0.95em;
}
.home main form .toggle-section label {
  display: flex;
  align-items: flex-start;
}
.home main form .toggle-section label .info p {
  margin-top: 0.5em;
  font-size: 0.9em;
  font-weight: 400;
}

.home main form button {
  background-color: var(--accent);
  color: var(--white);
  font-weight: 500;
  width: 100%;
  padding: 0.7em 1.5em;
  margin-bottom: 0em;
  border-radius: 1.2em;
  border-width: 2px;
  border: none;
  transition: background-color 0.2s ease-out;
  outline: none;
}
.home main form button:not(.disabled):hover,
.home main form button:not(.disabled):focus {
  background-color: var(--accent-dark);
}
.home main form button.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

footer {
  font-size: 0.6em;
  margin-bottom: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
}
footer .heart-icon {
  width: 1em;
  height: 1em;
  margin-right: 0.4em;
}
footer a {
  color: var(--accent);
  text-decoration: none;
  transition: color 0.2s ease-out;
}
footer a:hover,
footer a:focus {
  color: var(--accent-dark);
  text-decoration: underline;
}

@keyframes home_rotation {
  from {
    transform: translateY(-50%) rotate(45deg);
  }
  to {
    transform: translateY(-50%) rotate(405deg);
  }
}
