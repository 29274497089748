.about {
  font-size: min(max(16px, 4.7vw), 23px);
}

.about header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: inherit;
  z-index: 1;
  padding: 1.4em 1.4em 0.4em 1.4em;
  box-shadow: 0px 15px 15px #f5f5f5ff;
}
.about header h1 {
  font-size: 1.2em;
  font-weight: 500;
}
.about header .close {
  margin-right: -0.3em;
}

.about main {
  padding: 0.4em 1.4em 1.4em 1.4em;
}

.about main .authors {
  margin: 0.6em 0;
  display: flex;
  align-items: center;
}
.about main .authors .photos {
  flex-shrink: 0;
  display: flex;
  margin-right: 0.8em;
}
.about main .authors .photos img {
  display: inline-block;
  border-radius: 100%;
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.4));
}
.about main .authors .photos img:not(:last-child) {
  margin-right: -0.8em;
}
.about main .authors p {
  margin-top: -6px;
}

.about main h2 {
  font-size: 1em;
  font-weight: 500;
  margin: 1.4em 0 0.9em 0;
}
.about main p {
  font-size: 0.8em;
  line-height: 1.3;
}
.about main p:not(:last-child) {
  margin-bottom: 0.8em;
}
.about main a {
  color: var(--accent);
  text-decoration: none;
  transition: color 0.2s ease-out;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}
.about main a:hover,
.about main a:focus {
  color: var(--accent-dark);
  text-decoration: underline;
}
